import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import { storyblokEditable } from 'gatsby-source-storyblok';

import DynamicIcon from '../Icons/DynamicIcon';
import TextTitle from '../TextTitle';

const IconFeature = ({ blok }) => {
  const {
    text,
    icon,
    size = 'large',
    textSize = 'medium',
    direction = 'row',
  } = blok;
  return (
    <Box
      {...storyblokEditable(blok)}
      direction={direction}
      gap="medium"
      align="center"
      justify="center"
      width="100%"
    >
      <DynamicIcon type={icon} size={size} color={'black'} strokeWidth={0} />
      {text && (
        <TextTitle
          textAlign={direction === 'column' ? 'center' : 'left'}
          size={'small'}
        >
          {text}
        </TextTitle>
      )}
    </Box>
  );
};

IconFeature.propTypes = {
  blok: PropTypes.shape({
    text: PropTypes.string.isRequired,
    icon: PropTypes.string,
    size: PropTypes.oneOf([
      'xsmall',
      'small',
      'medium',
      'large',
      'xlarge',
      'xxlarge',
      '32px',
      '60px',
    ]),
    textSize: PropTypes.oneOf([
      'xsmall',
      'small',
      'medium',
      'large',
      'xlarge',
      'xxlarge',
    ]),
  }),
};

export default IconFeature;
